import { UserEmailChangeAttemptResponse } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useQuery } from '@tanstack/react-query'

import { adminService } from './AdminService'

const fetcher = async (id: number): Promise<UserEmailChangeAttemptResponse[]> => {
  try {
    return await adminService.getUserEmailChanges(id)
  } catch (e) {
    return []
  }
}

export const useAdminUserEmailChanges = (id: number) => {
  return useQuery(['/admin/user/email-changes'], () => fetcher(id))
}
