import { UserEmailUpdateRequest } from '@cleverbits-gmbh/iwimp-typescript-api'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { profileService } from 'lib/api/ProfileService'
import { useMessageActions } from 'lib/state'

const updateProfileEmail = (req: UserEmailUpdateRequest) => profileService.updateProfileEmail(req)

export const useProfileEmailUpdate = (onSuccess: () => void) => {
  const { addMessage } = useMessageActions()
  const queryClient = useQueryClient()
  return useMutation(updateProfileEmail, {
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['/profile/email'] })
      onSuccess()
      addMessage({
        type: 'success',
        autoCloseSeconds: 5,
        text: 'E-Mailänderungsanfrage erstellt. Sie bekommen eine Bestätigungsmail.'
      })
    },
    onError: () => {
      addMessage({ type: 'error', autoCloseSeconds: 5, text: 'E-Mail konnte nicht geändert werden.' })
    }
  })
}
