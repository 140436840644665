import { useCallback } from 'react'
import { UserRoleEnum } from '@cleverbits-gmbh/iwimp-typescript-api'
import create from 'zustand'

import { AuthService } from 'lib/auth/AuthService'
import { User } from 'lib/model'

interface UserState {
  user: User | null
  setUser: (user: User | null) => void
}

export const useAuthState = create<UserState>((set) => {
  return {
    user: null,
    setUser: (user) => set({ user })
  }
})

export const useUser = (): User => {
  return useAuthState(({ user }) => {
    if (!user) throw Error('User required!')
    return user
  })
}

export const useUserRoles = (role: UserRoleEnum | UserRoleEnum[]): boolean => {
  const { roles: userRoles } = useUser()
  const roles = Array.isArray(role) ? role : [role]
  return Boolean([UserRoleEnum.ADMIN, ...roles].find((r) => userRoles.includes(r)))
}

export const useLogout = (): ((path?: string) => Promise<void>) => {
  return useCallback(async (path = '/') => {
    if (!AuthService.isAuthenticated()) return
    await AuthService.doLogout(path)
  }, [])
}
